import {Injectable, OnInit} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import { addYears } from 'date-fns'
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  $errorSnack: Subject<string> = new Subject<string>()
  $successSnack: Subject<string> = new Subject<string>()
  $infoSnack: Subject<string> = new Subject<string>()

  constructor(
    private _cookies:CookieService
  ) {
    console.log("Utils Initiated")
  }

  sendMessageToSW(data: any) {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage(data);
    }
  }

  getObservableSnackSuccess(){
    return this.$successSnack.asObservable()
  }

  getObservableSnackError(){
    return this.$errorSnack.asObservable()
  }

  getObservableSnackInfo(){
    return this.$infoSnack.asObservable()
  }

  sendSnackError(message: string){
    this.$errorSnack.next(message)
  }
  sendSnackSuccess(message: string){
    this.$successSnack.next(message)
  }
  sendSnackInfo(message: string){
    this.$infoSnack.next(message)
  }

  setLanguage(language: "es" | "en"){
    this._cookies.set('jdLanguage', language, addYears(new Date(), 100), "")
  }

  getLanguage(){
    if(!this._cookies.check('jdLanguage')){
      throw new Error('Language not set')
    }
    return this._cookies.get('jdLanguage')
  }

  setAuthCookies(){

  }

  getAuthCookies(){

  }
}
