import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {CognitoService} from "../services/cognito.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private cognito: CognitoService,
    private router: Router
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognito.getCurrentUser()
      .then(data=>{
        const groups = data.getAccessToken().payload['cognito:groups']
        if(groups.filter((group: string) => group === "administrator").length > 0){
          this.router.navigate(['/panel/users'])
        }
        else if(groups.filter((group: string) => group === "worker").length > 0){
          this.router.navigate(['/worker/calendar'])
        }
        else if(groups.filter((group: string) => group === "client").length > 0){
          this.router.navigate(['/client/home'])
        }
        return false
      })
      .catch(data=>{
        return true
      })
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognito.getCurrentUser()
      .then(data=>{
        const groups = data.getAccessToken().payload['cognito:groups']
        if(groups.filter((group: string) => group === "administrator").length > 0){
          this.router.navigate(['/panel/users'])
        }
        else if(groups.filter((group: string) => group === "worker").length > 0){
          this.router.navigate(['/worker/calendar'])
        }
        else if(groups.filter((group: string) => group === "client").length > 0){
          //TODO
        }
        return false
      })
      .catch(data=>{
        return true
      })
  }

}
