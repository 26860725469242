import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {WorkerGuard} from "./modules/shared/guards/worker.guard";
import {AdministratorGuard} from "./modules/shared/guards/administrator.guard";
import {AuthGuard} from "./modules/shared/guards/auth.guard";

const routes: Routes = [
  {
    path: "worker",
    loadChildren: ()=> import('./modules/worker/worker.module').then(m=>m.WorkerModule)
  },
  {
    path: "client",
    loadChildren: ()=> import('./modules/client/client.module').then(m=>m.ClientModule)
  },
  {
    path: "panel",
    canActivate: [AdministratorGuard],
    canActivateChild: [AdministratorGuard],
    loadChildren: ()=> import('./modules/administrator/administrator.module').then(m=>m.AdministratorModule)
  },
  {
    path: "auth",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: ()=> import('./modules/auth/auth.module').then(m=>m.AuthModule)
  },
  {path: "**", redirectTo:"/auth"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
