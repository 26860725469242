import { Injectable } from '@angular/core';
import {Amplify, Auth} from "aws-amplify";
import {environment} from "../../../../environment/environment";
import {CookieService} from "ngx-cookie-service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(
    private _cookies: CookieService,
    private http: HttpClient
  ) {
    Amplify.configure({
      Auth: environment.cognito,
    });
  }

  changeTemporaryPassword({email, oldPassword, newPassword}: {email: string, oldPassword:string, newPassword: string}){
    return Auth.signIn({
      username: email,
      password: oldPassword
    })
      .then(user =>{
        return Auth.completeNewPassword(user, newPassword)
      })
      .catch(error=>{
        throw new Error(error)
      })
  }

  signIn(user: { password: string; username: string, remember: boolean}): Promise<any> {
    Auth.configure({
      storage: user.remember ? window.localStorage: window.sessionStorage
    })
    return Auth.signIn({
      username: user.username,
      password: user.password,
    });
  }

  signInApi(accessToken: string){
   return this.http.get(`${environment.api}/auth`,{
     headers:{
       authorization: `Bearer ${accessToken}`
     }
   })
  }

  logOut(){
    return Auth.signOut()
  }

  getCurrentUser(){
    return Auth.currentSession()
  }
}
