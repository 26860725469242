export const environment: any = {
  // api: 'http://192.168.0.26:3000',
  // api: 'http://localhost:3000',
  api: 'https://qiqi5nuqr5.execute-api.us-west-2.amazonaws.com',
  cognito: {
    userPoolId: "us-west-2_JyMpRSCTS",
    userPoolWebClientId: "44uuof20nip1cfed29ugcg19ko"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDRbbAVJPpFiV-DI1T9iZ3KGOTkRhotNx4",
    authDomain: "jd-app-93611.firebaseapp.com",
    projectId: "jd-app-93611",
    storageBucket: "jd-app-93611.appspot.com",
    messagingSenderId: "100986946567",
    appId: "1:100986946567:web:892823f438b2fe4f23d6a2",
    measurementId: "G-ET2QVKHB6S"
  }
}
